@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Noto Sans", Arial;
  scroll-behavior: smooth;
}

body {
  background-color: rgb(237, 237, 237);
}

.hero {
  background-image: url("./assets/hero.jpg");
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}
